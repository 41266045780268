import React from 'react'

const KlaviyoForm = ({ className, listId }) => {
  return (
    <div className={className}>
      <form
        action="https://manage.kmail-lists.com/subscriptions/subscribe?a=UVrcZL&g=SVniy3"
        target="_blank"
        className="klaviyo-form flex items-end mb-4"
        method="POST"
      >
        <input type="hidden" name="g" value={listId} />
        <input type="email" name="email" placeholder="E-postadresse" className="flex-1 focus:outline-none" />
        <button type="submit">Meld deg på</button>
      </form>

      <div className='mt-2 paragraph-4'>
        Ved å melde deg på vårt nyhetsbrev godtar du <a href="/nyhetsbrev-betingelser" target="_blank">betingelsene for nyhetsbrev</a>
      </div>
    </div>
  )
}

export default KlaviyoForm